import React from "react"

import Layout from "../components/layout"
import ContentContainer from "../components/contentContainer"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout style={{ minHeight: "100vh" }}>
    <SEO title="404: Not found" />
    <ContentContainer>
      <h1>404. We can't find this page!</h1>
    </ContentContainer>
  </Layout>
)

export default NotFoundPage
